import React from 'react';
import { Box, Typography } from '@mui/material';
import ProductCard from '../components/ProductCard';

const RelatedProducts = ({ relatedProducts }) => {
  return (
    <Box className="related-products">
      <Typography variant="h4" sx={{ mb: "2%" }}>Similar Products</Typography>
      <Box className="related-products-container">
        {relatedProducts.map((relatedProduct) => (
          <ProductCard className="related-product-card" key={relatedProduct._id} product={relatedProduct} />
        ))}
      </Box>
    </Box>
  );
};

export default RelatedProducts;
