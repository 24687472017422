import React, { useState, useRef, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  MenuItem,
  MenuList,
  Popover,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Hidden,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ShoppingBasket as ShoppingBasketIcon,
  AccountBox as AccountBoxIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Logo from '../images/Logo.png';
import './Header.css';
import { createSlug } from '../utils/slugify';


const minerals = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886112/minerals_u8drr7.jpg";
const vitamins = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886113/vitamins_c2olat.jpg";
const herbs = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886115/herbs_deqg15.jpg";
const supplements = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886113/supplements_kesk6s.jpg";
const lifestyle = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886112/lifestyle_b7nqig.jpg";
const sale = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886113/sale_vpxsvy.jpg";
const allproducts = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886116/allproducts_tui5pb.jpg";

const about = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886115/about_eg1mrc.jpg";
const partners = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886111/partners_idcxhf.jpg";
const practitioners = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886111/practitioners_epbev8.jpg";
const contactus = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886113/contactus_koboqy.jpg";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const menuItems = [
  {
    title: 'Categories',
    content: [
      { img: vitamins, text: 'Vitamins', link: '/category/Vitamin' },
      { img: minerals, text: 'Minerals', link: '/category/Mineral' },
      { img: herbs, text: 'Herbs', link: '/category/Herb' },
      { img: supplements, text: 'Supplements', link: '/category/Supplement' },
      { img: lifestyle, text: 'Lifestyle', link: '/category/Lifestyle' },
      { img: sale, text: 'On Sale', link: '/sales', largeSpace: true },
      { img: allproducts, text: 'All Products', link: '/category/All' },
    ],
  },
  {
    title: 'Explore',
    link: '/allproducts', // Add this line
    content: [
      {img: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639441/bone_lbkcss.jpg", text: "Bone Health", link: `/subcategory/${createSlug("Bone Health")}`},
      {img: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639442/brain_p36up0.jpg", text: "Brain Health", link: `/subcategory/${createSlug("Brain Health")}`},
      {img: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639410/children_an3h16.jpg", text: "Children's Health", link: `/subcategory/${createSlug("Children's Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639410/diabetic_g9ge3b.jpg', text: 'Diabetic Health', link: `/subcategory/${createSlug("Diabetic Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639411/digestive_h9pauw.jpg', text: 'Digestive Health', link: `/subcategory/${createSlug("Digestive Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639411/hair_jt6w7e.jpg', text: 'Hair, Skin & Nail Health', link: `/subcategory/${createSlug("Hair, Skin & Nail Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639411/heart_gccgew.jpg', text: 'Heart & Circulatory Health', link: `/subcategory/${createSlug("Heart & Circulatory Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639415/immune_hwa4kt.jpg', text: 'Immune Health', link: `/subcategory/${createSlug("Immune Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639418/liver_qzpwpg.jpg', text: 'Liver & Detox Health', link: `/subcategory/${createSlug("Liver & Detox Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639419/men_cklgbl.jpg', text: 'Men\'s Health', link: `/subcategory/${createSlug("Men's Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639430/sleep_xatk1l.jpg', text: 'Sleep & Relaxation', link: `/subcategory/${createSlug("Sleep & Relaxation")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639431/vision_aagaak.jpg', text: 'Vision Health', link: `/subcategory/${createSlug("Vision Health")}`},
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639432/women_oasink.jpg', text: 'Women\'s Health', link: `/subcategory/${createSlug("Women's Health")}`}, 
      {img: 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886116/allproducts_tui5pb.jpg', text: 'All Products', link: `/category/All`},
    ],
  },
  {
    title: 'About Us',
    content: [
      { img: about, text: 'Who We Are', link: '/about' },
      { img: partners, text: 'Our Partners', link: '/partners' },
      { img: contactus, text: 'Contact Us', link: '/ContactUs' },
    ],
  },
];

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentContent, setCurrentContent] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const headerRef = useRef(null);
  const popoverRef = useRef(null);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg')); // 1200px breakpoint
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    const token = localStorage.getItem('token');
    const loginDate = new Date(localStorage.getItem('login_date'));
    const currentDate = new Date();

    if (token && (currentDate - loginDate) < 72000000) { // 20 hours in milliseconds
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }

    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setCartCount(cartItems.length);
    }

    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        handlePopoverClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePopoverOpen = (content) => {
    setAnchorEl(headerRef.current);
    setCurrentContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentContent([]);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderMobileMenu = (
    <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
      <Box sx={{ width: 250 }} role="presentation" onClick={(e) => e.stopPropagation()}>
        <List>
          {menuItems.map((item, index) => (
            <NestedListItem key={index} title={item.title} content={item.content} />
          ))}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <AppBar position="static" color="default" ref={headerRef}>
      <Toolbar className="toolbar">
        <Hidden lgUp>
        <div className="menu-icon horizontal-align">
          <IconButton
            aria-label="show more"
            aria-haspopup="true"
            onClick={handleDrawerToggle}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Link to="/search" style={{ textDecoration: 'none' }}>
              <SearchIcon  className="shopping-basket-icon"/>
          </Link>
        </div>
        </Hidden>
        <Typography variant="h6" noWrap component="div" className="logo-container">
          <Link to="/">
            <img src={Logo} alt="Logo" className={`logo ${searchFocused ? 'hidden' : ''}`} />
          </Link>
        </Typography>
        <Hidden lgDown>
          <MenuList className="menu-list">
            {menuItems.map((item) => (
              item.title === 'Explore' ? (
                <MenuItem
                  key={item.title}
                  component={Link}
                  to={item.link}
                  className="menu-item"
                >
                  <Typography variant="h6" className="menu-item-text">
                    {item.title}
                  </Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  key={item.title}
                  aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={() => handlePopoverOpen(item.content)}
                  className="menu-item"
                >
                  <Typography variant="h6" className="menu-item-text">
                    {item.title}
                  </Typography>
                </MenuItem>
              )
            ))}
          </MenuList>
        </Hidden>
        <div className="icon-buttons">
          <Hidden lgDown>          
          <IconButton aria-label="search" color="inherit" sx={{ ml: 1, mt: 1.5 }}>
            <Link to="/search" style={{ textDecoration: 'none' }}>
              <SearchIcon  className="shopping-basket-icon"/>
            </Link>
          </IconButton>
          </Hidden>
          <IconButton aria-label="cart" color="inherit" sx={{ ml: 1 }}>
            <Link to="/Cart" style={{ textDecoration: 'none' }}>
              <StyledBadge badgeContent={cartCount} color="secondary">
                <ShoppingBasketIcon className="shopping-basket-icon" />
              </StyledBadge>
            </Link>
          </IconButton>
          {loggedIn ? (
            <Link to="/userhome" style={{ textDecoration: 'none' }}>
              <IconButton color="inherit">
                <AccountBoxIcon className="account-box-icon" />
              </IconButton>
            </Link>
          ) : (
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary" className="login-button">
                Login
              </Button>
            </Link>
          )}
        </div>
        {renderMobileMenu}
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none' }}
          open={Boolean(anchorEl)}
          anchorEl={headerRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box
            p={2}
            sx={{ pointerEvents: 'auto', width: '100%' }}
            onMouseEnter={() => setAnchorEl(headerRef.current)}
            onMouseLeave={handlePopoverClose}
            ref={popoverRef}
          >
            {isMobileView ? (
              <List>
                {currentContent.map((item, index) => (
                  <ListItem button component={Link} to={item.link} key={index}>
                    <ListItemIcon>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={40}
                        width={40}
                      >
                        <CardMedia
                          component="img"
                          style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                          image={item.img}
                          alt={item.text}
                        />
                      </Box>
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Grid container spacing={2} wrap="nowrap">
                {currentContent.map((item, index) => (
                  <Grid item key={index} sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto' }}>
                    <Link to={item.link} style={{ textDecoration: 'none' }}>
                      <Card className={`popover-card ${item.largeSpace ? 'popover-item-large-space' : 'popover-item'}`}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                          width="100%"
                        >
                          {typeof item.img === 'string' ? (
                            <CardMedia
                              component="img"
                              className="popover-card-media"
                              image={item.img}
                              alt={item.text}
                            />
                          ) : (
                            item.icon
                          )}
                        </Box>
                        <CardContent className="popover-card-content">
                          <Typography variant="body2" color="text.secondary" align="center">
                            {item.text}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

const NestedListItem = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={<Typography variant="h6">{title}</Typography>} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {content.map((subItem, index) => (
            <ListItem button component={Link} to={subItem.link} key={index} onClick={(e) => e.stopPropagation()}>
              <ListItemIcon>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={40}
                  width={40}
                >
                  <CardMedia
                    component="img"
                    style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                    image={subItem.img}
                    alt={subItem.text}
                  />
                </Box>
              </ListItemIcon>
              <ListItemText primary={subItem.text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default Header;
