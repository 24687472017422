import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import { NaturePeople, Verified, LocalShipping, ThumbUp } from '@mui/icons-material';
import logo from '../images/Logo.png';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <Box className="about-us-container">
      <Container maxWidth="lg">
        <Typography variant="h3" component="h1" gutterBottom className="title">
          About Us
        </Typography>
        <Typography variant="h6" className="subtitle" gutterBottom>
          Welcome to HerbiMed - Your Trusted Supplement Supplier
        </Typography>
        {/* <Box className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </Box> */}

        
        <Grid container spacing={4} className="sections">
          <Grid item xs={12} md={4}>
            <Paper className="section-paper" elevation={3}>
              <NaturePeople className="section-icon" />
              <Typography variant="h5" className="section-title" gutterBottom>Our Mission</Typography>
              <Typography variant="body1" className="section-text">
                Our mission is to promote a healthy lifestyle by offering a range of natural supplements that meet the highest standards of quality and efficacy. We believe in the power of nature to enhance wellbeing and strive to make these benefits accessible to everyone.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className="section-paper" elevation={3}>
              <Verified className="section-icon" />
              <Typography variant="h5" className="section-title" gutterBottom>Our Values</Typography>
              <Typography variant="body1" className="section-text">
                Integrity, quality, and customer satisfaction are at the heart of everything we do. We are dedicated to maintaining the highest standards and ensuring that our customers receive products that are safe, effective, and beneficial. Your Health is Our Biggest Priority.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className="section-paper" elevation={3}>
              <LocalShipping className="section-icon" />
              <Typography variant="h5" className="section-title" gutterBottom>Why Us?</Typography>
              <Typography variant="body1" className="section-text">
                At HerbiMed, we offer fast and reliable shipping, exceptional customer service, and a commitment to your health and satisfaction. Our products are crafted with care and backed by a satisfaction guarantee, making us the best choice for your supplement needs.
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4} className="additional-sections">
          <Grid item xs={12}>
            <Paper className="section-paper" elevation={3}>
              <ThumbUp className="section-icon" />
              <Typography variant="h5" className="section-title" gutterBottom>Quality You Can Trust</Typography>
              <Typography variant="body1" className="section-text">
                All our products are manufactured in state-of-the-art facilities, adhering to the strictest quality control standards. We source only the finest natural ingredients to ensure that every supplement we offer is safe and effective.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUs;
