import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  Snackbar,
  Alert,
  FormControl,
  Grid,
  Divider,
  FormControlLabel
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import './OfferDetails.css';

const OfferDetails = () => {
  const { id } = useParams();
  const [offer, setOffer] = useState({
    name: '',
    blanketDiscountPercentage: 0,
    products: [],
    bannerImage: '',
    startDate: '',
    endDate: '',
    globalEffect: false
  });
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();
  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  useEffect(() => {
    fetchOffer();
    fetchProducts();
  }, [id]);

  useEffect(() => {
    if (searchTerm) {
      const results = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, products]);

  const fetchOffer = async () => {
    try {
      const response = await axios.get(`/sales/${id}`);
      const fetchedOffer = response.data;
      setOffer(fetchedOffer);
      setSelectedProducts(fetchedOffer.products.map(p => ({
        productId: p.productId,
        name: p.name,
        price: p.price,
        discountPercentage: p.discountPercentage,
        image: p.image
      })));
    } catch (error) {
      console.error('Error fetching offer:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get('/products/search');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleProductCheckboxChange = (product) => {
    const existingProduct = selectedProducts.find(item => item.productId === product._id);
    if (existingProduct) {
      setSelectedProducts(selectedProducts.filter((item) => item.productId !== product._id));
    } else {
      setSelectedProducts([...selectedProducts, {
        productId: product._id,
        name: product.name,
        price: product.price,
        discountPercentage: offer.blanketDiscountPercentage,
        image: product.images[0].url
      }]);
    }
  };

  const handleDiscountChange = (productId, discountPercentage) => {
    setSelectedProducts(
      selectedProducts.map((item) =>
        item.productId === productId ? { ...item, discountPercentage } : item
      )
    );
  };

  const handleSaveOffer = async () => {
    try {
      const formData = new FormData();
      formData.append('name', offer.name);
      formData.append('blanketDiscountPercentage', offer.blanketDiscountPercentage);
      formData.append('startDate', offer.startDate);
      formData.append('endDate', offer.endDate || '');
      formData.append('globalEffect', offer.globalEffect);
      formData.append('products', JSON.stringify(selectedProducts));

      if (offer.bannerImage instanceof File) {
        formData.append('bannerImage', offer.bannerImage);
      }

      await axios.put(`/sales/${id}`, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });

      setSnackbarMessage('Offer updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchOffer();
    } catch (error) {
      setSnackbarMessage('Error updating offer');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error updating offer:', error.response?.data || error.message);
    }
  };

  const handleBannerUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOffer({ ...offer, bannerImage: file });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCancel = () => {
    navigate('/admin/sales');
  };

  const handleRemoveSelectedProduct = (productId) => {
    setSelectedProducts(selectedProducts.filter((item) => item.productId !== productId));
  };

  const handleGlobalEffectChange = (e) => {
    setOffer({ ...offer, globalEffect: e.target.checked });
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Offer Details
      </Typography>
      {offer.bannerImage && (
        <CardMedia
          component="img"
          height="200"
          image={offer.bannerImage instanceof File 
            ? URL.createObjectURL(offer.bannerImage) 
            : `${offer.bannerImage.split('/upload/')[0]}/upload/f_auto/${offer.bannerImage.split('/upload/')[1]}`
          }
          alt={offer.name}
          className="banner-preview"
        />
      
      )}
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" alignItems="center">
          <FormControl>
            <Button
              variant="contained"
              component="label"
              color="primary"
              sx={{ width: '200px' }}
            >
              Upload Banner
              <input type="file" hidden onChange={handleBannerUpload} />
            </Button>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={offer.globalEffect}
                onChange={handleGlobalEffectChange}
                color="primary"
              />
            }
            label="Global Effect"
            sx={{ marginLeft: '32%' }}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Offer Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={offer.name || ''}
              onChange={(e) => setOffer({ ...offer, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Blanket Discount Percentage"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              value={offer.blanketDiscountPercentage || 0}
              onChange={(e) => setOffer({ ...offer, blanketDiscountPercentage: Number(e.target.value) })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Start Date"
              variant="outlined"
              fullWidth
              margin="normal"
              type="date"
              value={offer.startDate ? offer.startDate.split('T')[0] : ''}
              onChange={(e) => setOffer({ ...offer, startDate: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="End Date"
              variant="outlined"
              fullWidth
              margin="normal"
              type="date"
              value={offer.endDate ? offer.endDate.split('T')[0] : ''}
              onChange={(e) => setOffer({ ...offer, endDate: e.target.value || '' })}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Typography variant="h6">Selected Products</Typography>
        <Grid container spacing={2}>
          {selectedProducts.map((selectedProduct) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={selectedProduct.productId}>
              <Card className="selected-product-card">
              <CardMedia
                component="img"
                height="140"
                image={`${selectedProduct.image.split('/upload/')[0]}/upload/f_auto/${selectedProduct.image.split('/upload/')[1]}`}
                alt={selectedProduct.name}
                className="banner-preview"
              />

                <CardContent>
                  <Typography variant="body1">{selectedProduct.name}</Typography>
                  <Typography variant="body2">Price: Rs {selectedProduct.price}</Typography>
                  <TextField
                    label="Discount %"
                    variant="outlined"
                    type="number"
                    margin="normal"
                    value={selectedProduct.discountPercentage || 0}
                    onChange={(e) => handleDiscountChange(selectedProduct.productId, Number(e.target.value))}
                    style={{ width: '100%' }}
                  />
                  <IconButton color="secondary" onClick={() => handleRemoveSelectedProduct(selectedProduct.productId)}>
                    <Delete />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: 3 }} />
        <TextField
          label="Search Products"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <List className="search-results-list">
          {searchResults.map((product) => (
            <ListItem key={product._id} className="list-item">
              <ListItemAvatar>
                <Avatar 
                  src={product.images && product.images[0]
                    ? `${product.images[0].url.split('/upload/')[0]}/upload/f_auto/${product.images[0].url.split('/upload/')[1]}`
                    : ''
                  }
                  alt={product.name}
                />
              </ListItemAvatar>

              <ListItemText
                primary={product.name}
                secondary={`Price: Rs ${product.price}`}
              />
              <Checkbox
                checked={selectedProducts.some((item) => item.productId === product._id)}
                onChange={() => handleProductCheckboxChange(product)}
                color="primary"
              />
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button variant="contained" color="primary" onClick={handleSaveOffer}>
            Save Changes
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default OfferDetails;
