import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Modal, Box, Snackbar, Alert, CircularProgress, Pagination, Autocomplete } from '@mui/material';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [testEmailModalOpen, setTestEmailModalOpen] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [testEmail, setTestEmail] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [editingPoints, setEditingPoints] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const adminEmail = localStorage.getItem('adminEmail');
  const restrictedEmails = ['gulberg@herbimed.pk', 'dha@herbimed.pk'];
  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  useEffect(() => {
    fetchUsers();
  }, [currentPage, searchQuery]);

  const fetchUsers = () => {
    axios.get('/user/getallusers', { params: { page: currentPage, search: searchQuery }, headers })
      .then(response => {
        setUsers(response.data.users || []);
        setTotalPages(response.data.totalPages || 1);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
        setUsers([]);
      });
  };

  const handleEditPoints = (user) => {
    setEditingUser(user);
    setEditingPoints(user.loyaltyPoints);
  };

  const handleSavePoints = () => {
    axios.post('/user/updateloyalty', { userId: editingUser._id, loyaltyPoints: editingPoints }, { headers })
      .then(response => {
        setUsers(users.map(user => user._id === editingUser._id ? { ...user, loyaltyPoints: editingPoints } : user));
        setEditingUser(null);
        setEditingPoints('');
        setSnackbarMessage('Loyalty points updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('Error updating loyalty points:', error);
        setSnackbarMessage('Error updating loyalty points');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleSendEmail = () => {
    if (restrictedEmails.includes(adminEmail)) {
      alert('You are not authorized to send mass emails.');
      return;
    }
    setIsSendingEmail(true);
    axios.post('/admin/sendmassemail', { subject: emailSubject, content: emailContent }, { headers })
      .then(response => {
        setSnackbarMessage('Email sent successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setEmailModalOpen(false);
        setEmailContent('');
        setEmailSubject('');
        setIsSendingEmail(false);
      })
      .catch(error => {
        console.error('Error sending email:', error);
        setSnackbarMessage('Error sending email');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsSendingEmail(false);
      });
  };

  const handleSendTestEmail = () => {
    setIsSendingTestEmail(true);
    axios.post('/admin/sendtestemail', { email: testEmail, subject: emailSubject, content: emailContent }, { headers })
      .then(response => {
        setSnackbarMessage('Test email sent successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTestEmailModalOpen(false);
        setTestEmail('');
        setEmailContent('');
        setEmailSubject('');
        setIsSendingTestEmail(false);
      })
      .catch(error => {
        console.error('Error sending test email:', error);
        setSnackbarMessage('Error sending test email');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsSendingTestEmail(false);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSearchChange = (event, value) => {
    setSearchQuery(value);
    fetchAutocompleteOptions(value);
  };

  const fetchAutocompleteOptions = (query) => {
    axios.get('/user/getallusers', { params: { search: query }, headers })
      .then(response => {
        setAutocompleteOptions(response.data.users.map(user => user.name) || []);
      })
      .catch(error => {
        console.error('Error fetching autocomplete options:', error);
        setAutocompleteOptions([]);
      });
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Autocomplete
          freeSolo
          options={autocompleteOptions}
          onInputChange={handleSearchChange}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label="Search Users" 
              variant="outlined" 
              fullWidth 
              InputProps={{
                ...params.InputProps,
                style: { 
                  padding: '8px 12px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '4px',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                  width:"200px"
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '16px',
                  color: '#333',
                },
              }}
            />
          )}
        />
        <Box>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setEmailModalOpen(true)}
            disabled={restrictedEmails.includes(adminEmail)}
            style={{ marginRight: 10 }}
          >
            Send Email to All Users
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setTestEmailModalOpen(true)}
            style={{ marginRight: 10 }}
          >
            Send Test Email
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => window.open('https://wordtohtml.net/email/designer', '_blank')}
          >
            Open Email Editor
          </Button>
        </Box>
      </Box>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Loyalty Points</TableCell>
              <TableCell>Tier</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {editingUser && editingUser._id === user._id ? (
                    <TextField 
                      value={editingPoints}
                      onChange={(e) => setEditingPoints(e.target.value)}
                      type="number"
                    />
                  ) : (
                    user.loyaltyPoints
                  )}
                </TableCell>
                <TableCell>{user.tier}</TableCell>
                <TableCell>
                  {editingUser && editingUser._id === user._id ? (
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleSavePoints}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button 
                      variant="contained" 
                      onClick={() => handleEditPoints(user)}
                    >
                      Edit Points
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination 
          count={totalPages} 
          page={currentPage} 
          onChange={handlePageChange} 
          color="primary" 
        />
      </Box>
      
      <Modal
        open={emailModalOpen}
        onClose={() => setEmailModalOpen(false)}
      >
        <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', maxWidth: 500, borderRadius: 4, boxShadow: 24 }}>
          <h2>Send Mass Email</h2>
          <TextField 
            fullWidth
            label="Subject"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            style={{ marginBottom: 20 }}
          />
          <TextField 
            fullWidth
            multiline
            rows={10}
            placeholder="<p>Enter your email html here</p>"
            value={emailContent}
            onChange={(e) => setEmailContent(e.target.value)}
          />
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            {isSendingEmail ? (
              <CircularProgress />
            ) : (
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSendEmail}
                style={{ marginTop: 20 }}
              >
                Send Email
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal
        open={testEmailModalOpen}
        onClose={() => setTestEmailModalOpen(false)}
      >
        <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', maxWidth: 500, borderRadius: 4, boxShadow: 24 }}>
          <h2>Send Test Email</h2>
          <TextField 
            fullWidth
            label="Test Email Address"
            value={testEmail}
            onChange={(e) => setTestEmail(e.target.value)}
            style={{ marginBottom: 20 }}
          />
          <TextField 
            fullWidth
            label="Subject"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            style={{ marginBottom: 20 }}
          />
          <TextField 
            fullWidth
            multiline
            rows={10}
            placeholder="<p>Enter your email html here</p>"
            value={emailContent}
            onChange={(e) => setEmailContent(e.target.value)}
          />
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            {isSendingTestEmail ? (
              <CircularProgress />
            ) : (
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSendTestEmail}
                style={{ marginTop: 20 }}
              >
                Send Test Email
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UsersPage;
