import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress, Box, Grid, Typography } from '@mui/material';
import axios from '../axiosConfig'; // Import the axios configuration
import ProductCard from '../components/ProductCard'; // Import the ProductCard component
import './SearchPage.css'; // Import the CSS file for styling

const SearchPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchQuery.length > 2) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`/products/search?search=${searchQuery}`);
          setProducts(response.data);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
        setLoading(false);
      };
      fetchData();
    } else {
      setProducts([]);
    }
  }, [searchQuery]);

  return (
    <div className="search-page">
      <Box className="search-bar-container">
        <TextField
          label="Search Products"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <div className="search-results">
          {searchQuery.length === 0 ? (
            <Typography align="center" sx={{ fontSize: '24px', marginBottom: '20px', marginTop: '30px' }}>
              Start searching for products...
            </Typography>
          ) : products.length === 0 ? (
            <Typography align="center" sx={{ fontSize: '24px', marginBottom: '20px', marginTop: '30px' }}>
              No products found for "{searchQuery}"
            </Typography>
          ) : (
            <>
              <Typography 
                align="center" 
                sx={{ 
                  fontSize: '24px', 
                  marginBottom: '20px', 
                  marginTop: '30px', 
                  wordWrap: 'break-word', 
                  whiteSpace: 'normal' 
                }}
              >
                Showing {products.length} results for "{searchQuery}"
              </Typography>
              <Grid container spacing={3}>
                {products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={product._id}>
                    <ProductCard product={product} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
