import React from 'react';
import { Box, Container, Grid, Paper, Typography, Avatar, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './OurPractitioners.css';

// Sample data for practitioners
const practitioners = [
  {
    name: 'Dr. Salim Shah',
    qualification: 'MBBS, MD (Nutrition)',
    contact: '+92 312 3456789',
    clinicMapLink: 'https://www.google.com/maps/place/Clinic1/',
    clinicMapImageUrl: 'https://static-maps.yandex.ru/1.x/?lang=en_US&ll=74.3587,31.5204&z=15&size=150,150&l=map&pt=74.3587,31.5204,pm2rdm',
    avatarUrl: 'https://via.placeholder.com/150'
  },
  {
    name: 'Dr. Jawad Khan',
    qualification: 'BSc (Hons), MSc (Nutrition)',
    contact: '+92 333 1234567',
    clinicMapLink: 'https://www.google.com/maps/place/Clinic2/',
    clinicMapImageUrl: 'https://static-maps.yandex.ru/1.x/?lang=en_US&ll=74.3544,31.5144&z=15&size=150,150&l=map&pt=74.3544,31.5144,pm2rdm',
    avatarUrl: 'https://via.placeholder.com/150'
  },
  // Add more practitioners as needed
];

const OurPractitioners = () => {
  return (
    <Box className="practitioners-container">
      <Container maxWidth="lg">
        <Typography variant="h3" component="h1" gutterBottom className="title">
          Our Practitioners
        </Typography>
        <Typography variant="body1" className="subtitle" gutterBottom>
          Meet our team of qualified Doctors and Nutritionists.
        </Typography>
        
        <Grid container spacing={4} justifyContent="center" sx={{marginTop:"30px"}}>
          {practitioners.map((practitioner, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper className="practitioner-paper" elevation={3}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar src={practitioner.avatarUrl} alt={practitioner.name} className="practitioner-avatar" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h5" className="practitioner-name">{practitioner.name}</Typography>
                    <Typography variant="body1" className="practitioner-qualification">{practitioner.qualification}</Typography>
                    <Typography variant="body2" className="practitioner-contact">{practitioner.contact}</Typography>
                  </Grid>
                  <Grid item>
                    <a href={practitioner.clinicMapLink} target="_blank" rel="noopener noreferrer">
                      <img src={practitioner.clinicMapImageUrl} alt="Map to Clinic" className="map-image" />
                    </a>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurPractitioners;
