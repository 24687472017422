import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Stepper, Step, StepLabel, Card, CardContent, Modal, Button, CircularProgress, Divider, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from '../axiosConfig';
import './OrderDetail.css';

const OrderDetail = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingCompany, setTrackingCompany] = useState('');
  const [customCompany, setCustomCompany] = useState('');
  const [loading, setLoading] = useState(false);
  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  useEffect(() => {
    axios.get(`/orders/${id}`, { headers })
      .then(response => {
        setOrder(response.data);
        setStatus(response.data.status);
      })
      .catch(error => console.error('Error fetching order:', error));
  }, [id]);

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;

    if (newStatus === 'Shipped') {
      setModalOpen(true);
    } else {
      setLoading(true);
      await updateStatus(newStatus);
      setLoading(false);
    }
  };

  const updateStatus = async (newStatus) => {
    try {
      await axios.put('/orders/status', { orderId: id, status: newStatus }, { headers });
      setOrder((prevOrder) => ({ ...prevOrder, status: newStatus }));
      setStatus(newStatus);
      alert('Order status updated successfully');
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const handleModalSubmit = async () => {
    setModalOpen(false);
    try {
      setLoading(true);
      await axios.put('/orders/courier', {
        orderId: id,
        courier: { name: trackingCompany === 'Other' ? customCompany : trackingCompany, trackingNumber }
      }, { headers });
      await updateStatus('Shipped');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error updating courier information:', error);
      alert('Error updating courier information');
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setStatus(order.status); // Revert status to previous value if modal is closed
  };

  const handleCompanyChange = (event) => {
    setTrackingCompany(event.target.value);
    if (event.target.value !== 'Other') {
      setCustomCompany('');
    }
  };

  if (!order) {
    return <Typography>Loading...</Typography>;
  }

  const steps = ['Not processed', 'Processing', 'Shipped', 'Delivered', 'Cancelled'];

  return (
    <Box className="order-detail">
      <Typography variant="h4" gutterBottom>Order Details</Typography>
      <Box className="order-info-wrapper">
        <Card className="order-info-card">
          <CardContent>
            <Typography variant="h6"><strong>Order ID:</strong> {order._id}</Typography>
            <Typography><strong>Amount:</strong> Rs {order.amount}</Typography>
            <Typography><strong>Name:</strong> {order.user ? order.user.name : 'Guest'}</Typography>
            <Typography><strong>Email:</strong> {order.email}</Typography>
            <Typography><strong>Phone Number:</strong> {order.phoneNumber}</Typography>
            <Typography><strong>City:</strong> {order.city}</Typography>
            <Typography><strong>Address:</strong> {order.address}</Typography>
            <Typography><strong>Type:</strong> {order.type}</Typography>
            <Typography><strong>Date:</strong> {new Date(order.createdAt).toLocaleString()}</Typography>
          </CardContent>
        </Card>
        {order.summary && (
          <Card className="order-summary-card">
            <CardContent>
              <Typography variant="h6" className="summary-title">Order Summary</Typography>
              <Divider className="summary-divider" />
              <Box className="summary-item">
                <Typography><strong>Subtotal:</strong></Typography>
                <Typography>Rs {order.summary.subtotal}</Typography>
              </Box>
              <Box className="summary-item">
                <Typography><strong>Discount:</strong></Typography>
                <Typography>Rs {order.summary.discount}</Typography>
              </Box>
              <Box className="summary-item">
                <Typography><strong>Delivery Charges:</strong></Typography>
                <Typography>Rs {order.summary.deliveryCharges}</Typography>
              </Box>
              <Divider className="summary-divider" />
              <Box className="summary-total">
                <Typography variant="h6"><strong>Total:</strong></Typography>
                <Typography variant="h6">Rs {order.summary.total}</Typography>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
      <Box className="order-status">
        <Typography variant="h6">Order Status</Typography>
        <Stepper activeStep={steps.indexOf(order.status)} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <TextField
          select
          value={status}
          onChange={handleStatusChange}
          className="status-select"
          SelectProps={{
            native: true,
          }}
          disabled={loading}
        >
          {steps.map(step => (
            <option key={step} value={step}>{step}</option>
          ))}
        </TextField>
        {loading && <CircularProgress size={24} className="status-spinner" />}
      </Box>
      <Typography variant="h6" gutterBottom>Products</Typography>
      <Box className="products-container">
        {order.products.map(product => (
          <Card key={product.product} className="product-card">
            <CardContent>
              <img 
                src={`${product.image.split('/upload/')[0]}/upload/f_auto/${product.image.split('/upload/')[1]}`} 
                alt={product.name} 
                className="product-image" 
              />
              <Typography><strong>Name:</strong> {product.name}</Typography>
              <Typography><strong>Price:</strong> Rs {product.price}</Typography>
              <Typography><strong>Quantity:</strong> {product.count}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box className="modal-content">
          <Typography variant="h6" gutterBottom>Enter Courier Details</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Courier Company</InputLabel>
            <Select
              value={trackingCompany}
              onChange={handleCompanyChange}
            >
              <MenuItem value="TCS">TCS</MenuItem>
              <MenuItem value="Leopard">Leopard</MenuItem>
              <MenuItem value="M&P">M&P</MenuItem>
              <MenuItem value="Herbi Med Team">Herbi Med Team</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          {trackingCompany === 'Other' && (
            <TextField
              label="Enter Courier Company"
              value={customCompany}
              onChange={(e) => setCustomCompany(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
          <TextField
            label="Tracking Number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Box className="modal-buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalSubmit}
              disabled={!trackingNumber || (!trackingCompany && !customCompany) || loading}
            >
              Submit
            </Button>
            <Button variant="contained" color="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderDetail;
