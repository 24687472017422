import React from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import './DescriptionBox.css';

const formatContent = (content) => {
  if (typeof content !== 'string') {
    return '';
  }
  return content
    .replace(/\n/g, '<br />') // Replace new line characters with <br />
    .replace(/(\*\*|__)(.*?)\1/g, '<b>$2</b>') // Bold formatting **text** or __text__
    .replace(/(\*|_)(.*?)\1/g, '<i>$2</i>'); // Italic formatting *text* or _text_
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-content">
          <Typography
            dangerouslySetInnerHTML={{ __html: children }}
            className="tab-text"
          />
        </Box>
      )}
    </div>
  );
};

const DescriptionBox = ({ description, ingredients, directions, warning, tabIndex, handleTabChange }) => {
  return (
    <Box className="product-tabs">
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        className="tabs"
      >
        <Tab label="Description" className="tab" />
        <Tab label="Supplements Facts" className="tab" />
        <Tab label="Directions" className="tab" />
        <Tab label="Warnings" className="tab" />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        {formatContent(description)}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {formatContent(ingredients)}
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        {formatContent(directions)}
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        {formatContent(warning)}
      </TabPanel>
    </Box>
  );
};

export default DescriptionBox;
