const slugify = require('slugify');

// Convert a string to a URL-friendly slug
const createSlug = (text) => {
  return slugify(text.replace(/'s\b/g, '=s='), { // Use '=s=' as a marker for possessive 's
    lower: true,                 // Convert to lowercase
    remove: /[*+~.()'"!:@]/g,    // Remove special characters except single quotes
  }).replace(/'/g, '');          // Remove single quotes separately to preserve possessive handling
};

// Convert a slug back to the original string format
const deslugify = (slug) => {
  return slug
    .replace(/-and-/g, ' & ')     // Replace '-and-' with ' & '
    .replace(/-/g, ' ')           // Replace dashes with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase())  // Capitalize first letter of each word
    .replace(/=s=/g, "'s");       // Handle possessive 's using the unique marker
};

module.exports = { createSlug, deslugify };
