import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, CssBaseline, Avatar, Paper, Snackbar, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../axiosConfig';
import './ChangePassword.css';

const ChangePassword = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Required'),
      newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
          '/user/change-password',
          {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.status === 'success') {
          setSnackbarMessage('Password changed successfully.');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('Error changing password.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    },
  });

  return (
    <Box className="change-password-background">
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper elevation={3} className="paper-container">
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            <form onSubmit={formik.handleSubmit} style={{ width: '100%', mt: 3 }}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="oldPassword"
                label="Old Password"
                name="oldPassword"
                type="password"
                autoFocus
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                type="password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="confirmPassword"
                label="Confirm New Password"
                name="confirmPassword"
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                Change Password
              </Button>
            </form>
          </Box>
        </Paper>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ChangePassword;
