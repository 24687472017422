import React, { useEffect, useState } from "react";
import axios from '../axiosConfig';
import { Box, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Select, FormControl, InputLabel, Button, Snackbar, Pagination, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import "./ListProduct.css";

const ListProduct = () => {
  const [products, setProducts] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [quantityFilter, setQuantityFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const productsPerPage = 10;

  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  const fetchProducts = async (page = 1, category = '', quantity = '', term = '') => {
    setLoading(true);
    try {
      const endpoint = term ? '/products/search' : '/products';
      const response = await axios.get(endpoint, {
        params: {
          page,
          limit: productsPerPage,
          category,
          quantityFilter: quantity,
          search: term
        },
        headers
      });
      setProducts(response.data.products || response.data || []);
      setTotalPages(response.data.totalPages || Math.ceil(response.data.length / productsPerPage));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(currentPage, categoryFilter, quantityFilter, searchTerm);
  }, [currentPage, categoryFilter, quantityFilter, searchTerm]);

  const handleDeleteClick = (id) => {
    setProductToDelete(id);
    setConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/products/${productToDelete}`, { headers });
      setConfirmDialogOpen(false);
      fetchProducts(currentPage, categoryFilter, quantityFilter, searchTerm);
      setSnackbarMessage('Product removed successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error removing product:", error);
      setSnackbarMessage('Error removing product');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseDialog = () => {
    setConfirmDialogOpen(false);
    setProductToDelete(null);
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleQuantityChange = (event) => {
    setQuantityFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box className="listproduct">
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>All Products List</Typography>
      </Box>

      <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2} style={{ gap: '20px' }}>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Sort by Category</InputLabel>
          <Select
            value={categoryFilter}
            onChange={handleCategoryChange}
            label="Category"
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="Vitamins">Vitamins</MenuItem>
            <MenuItem value="Minerals">Minerals</MenuItem>
            <MenuItem value="Herbs">Herbs</MenuItem>
            <MenuItem value="Supplements">Supplements</MenuItem>
            <MenuItem value="Lifestyle">Lifestyle</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Sort by Quantity</InputLabel>
          <Select
            value={quantityFilter}
            onChange={handleQuantityChange}
            label="Quantity"
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="<10">{'< 10 units'}</MenuItem>
            <MenuItem value="outOfStock">Out of Stock</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TextField
        label="Search Products"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        size="small"
        style={{ width: '300px', margin: '20px 20px', borderRadius: '50px' }}
      />

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} className="listproduct-table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Inventory</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Subcategory</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product._id}>
                    <TableCell>
                      {product.images && product.images.length > 0 && product.images[0].url ? (
                        <img
                          className="listproduct-product-icon"
                          src={`${product.images[0].url.split('/upload/')[0]}/upload/f_auto/${product.images[0].url.split('/upload/')[1]}`}
                          alt={product.name}
                        />
                      ) : (
                        <img
                          className="listproduct-product-icon"
                          src="https://media.istockphoto.com/id/1055079680/vector/black-linear-photo-camera-like-no-image-available.jpg?s=612x612&w=0&k=20&c=P1DebpeMIAtXj_ZbVsKVvg-duuL0v9DlrOZUvPG6UJk="
                          alt="No Image Available"
                        />
                      )}
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.price}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>{product.category}</TableCell>
                    <TableCell>{product.subcategory}</TableCell>
                    <TableCell>
                      <Link to={`/admin/editproduct/${product._id}`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton onClick={() => handleDeleteClick(product._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>


            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            className="pagination"
          />
        </>
      )}
    </Box>
  );
};

export default ListProduct;
