import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Pagination, CircularProgress } from '@mui/material';
import axios from '../axiosConfig';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination as SwiperPagination, Navigation } from 'swiper/modules';
import ProductCard from '../components/ProductCard';
import './Sales.css';

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const salesPerPage = 10;

  useEffect(() => {
    fetchSales(currentPage);
  }, [currentPage]);

  const fetchSales = async (page) => {
    setLoading(true);
    try {
      console.log(`Fetching sales for page ${page} with limit ${salesPerPage}`);
      const response = await axios.get(`/sales/all`, {
        params: {
          page,
          limit: salesPerPage
        }
      });
      console.log('Fetched sales response:', response.data);
      setSales(response.data.sales);
      console.log(response.data.sales);
      setTotalSales(response.data.totalSales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box className="carousel-container">
        <Swiper
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
          className="simpleCarousel"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          modules={[SwiperPagination, Navigation, Autoplay]}
        >
          {sales.map((sale, index) => (
            sale.bannerImage && (
              <SwiperSlide key={index}>
                <img src={`${sale.bannerImage.split('/upload/')[0]}/upload/f_auto/${sale.bannerImage.split('/upload/')[1]}`} alt={`Sale ${index}`} style={{objectFit:"contain"}}/>
              </SwiperSlide>

            )
          ))}
        </Swiper>
      </Box>
      <Box className="sales-content">
        <Typography variant="h4" align="center" gutterBottom>On Sale</Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {sales.map((sale, index) => (
              <Box key={index} className="sale-section">
                <Typography variant="h5" gutterBottom>{sale.name}</Typography>
                <Grid container spacing={3}>
                  {sale.products.map((product, idx) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                      <ProductCard product={product} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={Math.ceil(totalSales / salesPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Sales;
