import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from '../axiosConfig';
import { Box, Typography, TextField, Button, Paper, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './PinVerification.css';

const PinVerification = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success'); // success or error
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      pin: '',
    },
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/user/verify-pin', {
          pin: values.pin,
        });
        console.log(response.data);
        if (response.data.status === "success") {
          setAlertMessage('Account verified successfully!');
          setAlertSeverity('success');
          setShowAlert(true);
          setTimeout(() => {
            navigate('/login');
          }, 2000); // Redirect after 2 seconds
        } else {
          setAlertMessage('Invalid PIN. Please try again.');
          setAlertSeverity('error');
          setShowAlert(true);
        }
      } catch (error) {
        console.error('Error verifying PIN:', error);
        setAlertMessage('An error occurred. Please try again.');
        setAlertSeverity('error');
        setShowAlert(true);
      }
    },
  });

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <Box className="pin-verification-container">
      <Paper elevation={3} className="pin-verification-paper">
        <Typography variant="h4" gutterBottom>
          Pin Verification
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            label="Enter PIN"
            name="pin"
            value={formik.values.pin}
            onChange={formik.handleChange}
            margin="normal"
            variant="outlined"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
          >
            Verify
          </Button>
        </form>
      </Paper>
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PinVerification;
