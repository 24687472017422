import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Avatar, Grid, CircularProgress, Snackbar, Alert, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import SilverIcon from '@mui/icons-material/EmojiEvents'; // Cup icon for Silver tier
import GoldIcon from '@mui/icons-material/Star'; // Star icon for Gold tier
import './ProfilePage.css';

const ProfilePage = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    phone: '',
    loyaltyPoints: 0,
    tier: 'silver',
  });
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('/user/profile', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user data', error);
          setLoading(false);
        }
      } else {
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSaveChanges = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.put('/user/profile', user, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Profile updated successfully:', response.data);
        setOpenSnackbar(true); // Open the snackbar on success
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  };

  const calculateProgress = (points) => {
    if (points < 1000) return (points / 1000) * 100;
    return 100;
  };

  const tier = user.tier;
  const pointsNeeded = {
    silver: 1000,
    gold: 1000,
  };

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh"><CircularProgress /></Box>;
  }

  return (
    <Box className="profile-container">
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Paper elevation={3} className="profile-paper">
            <Avatar alt={user.name} src="/static/images/avatar/1.jpg" sx={{ width: 80, height: 80, marginBottom: 2 }} />
            <Typography variant="h4" gutterBottom>
              {user.name}
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              {user.email}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Loyalty Points: {user.loyaltyPoints}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Tier: {tier.charAt(0).toUpperCase() + tier.slice(1)}
            </Typography>
            <Box className="progress-bar-container">
              <Typography variant="body1" gutterBottom>
                Progress to next tier:
              </Typography>
              <LinearProgress
                variant="determinate"
                value={calculateProgress(user.loyaltyPoints)}
                className={`progress-bar ${tier}`}
              />
              <Typography variant="body2" color="textSecondary">
                {user.loyaltyPoints} / {pointsNeeded[tier]} points
              </Typography>
            </Box>
          </Paper>
          <Grid container spacing={2} className="profile-grid">
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name="name"
                value={user.name}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                name="address"
                value={user.address}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={user.city}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                name="phone"
                value={user.phone}
                onChange={handleInputChange}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleSaveChanges} className="profile-save-button">
            Save Changes
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Paper elevation={3} className="benefit-card silver">
                <SilverIcon className="benefit-icon" />
                <Typography variant="h6">Silver Member</Typography>
                <Typography variant="body2">Below 1000 points</Typography>
                <Typography variant="body1">10% discount on all purchases.</Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper elevation={3} className="benefit-card gold">
                <GoldIcon className="benefit-icon" />
                <Typography variant="h6">Gold Member</Typography>
                <Typography variant="body2">1000 points and above</Typography>
                <Typography variant="body1">20% discount on all purchases.</Typography>
                <Typography variant="body2">Qualifies for gifts with purchases.</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Profile updated successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProfilePage;
