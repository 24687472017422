import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Typography, Container, CssBaseline, Avatar, Paper, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import Logo from '../images/Logo.png';
import './UserLogin.css';
import ImageFlipper from '../components/ImageFlipper';

const card1_front = "https://res.cloudinary.com/dv71k4jqz/image/upload/v1721639442/card1_front_hybsjz.jpg";
const card1_back = "https://res.cloudinary.com/dv71k4jqz/image/upload/v1721639442/card1_back_xanl54.jpg";
const card2_front = "https://res.cloudinary.com/dv71k4jqz/image/upload/v1721639411/card2_front_vz9fwv.jpg";
const card2_back = "https://res.cloudinary.com/dv71k4jqz/image/upload/v1721639411/card2_back_hyew6g.jpg";

const UserLogin = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
  let navigate = useNavigate();

  const handleSwitch = () => {
    setIsSignUp(!isSignUp);
    setAlertMessage('');
    setShowAlert(false);
    setShowForgotPassword(false);
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post('/user/send-password-reset-email', { email: forgotPasswordEmail });
      if (response.data.status === 'success') {
        setForgotPasswordMessage('A new password has been sent to your email.');
      } else {
        setForgotPasswordMessage(response.data.message);
      }
    } catch (error) {
      setForgotPasswordMessage('Error sending password reset email.');
    }
  };

  const loginFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/user/login', {
          email: values.email,
          password: values.password,
        });

        if (response.data.status === "success") {
          localStorage.setItem('userID', response.data.user._id);
          localStorage.setItem('token', response.data.token); // Ensure token is saved correctly
          localStorage.setItem('login_date', new Date().toISOString()); // Save the login date
          navigate('/');
        }
      } catch (error) {
        setAlertMessage('Incorrect Email or Password. Please try again.');
        setShowAlert(true);
      }
    },
  });

  const registerFormik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/user/register', {
          name: values.name,
          email: values.email,
          password: values.password,
        });

        if (response.data.status === "success") {
          navigate('/verify-pin');
        }
      } catch (error) {
        setAlertMessage(error.response.data.message);
        setShowAlert(true);
      }
    },
  });

  return (
    <Box className="login-background">
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Paper elevation={3} className="paper-container">
          <Grid container>
            <Grid item xs={12} md={6} className="form-container">
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={Logo} alt="Logo" className="login-logo" />
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {isSignUp ? 'Sign Up' : showForgotPassword ? 'Forgot Password' : 'Sign In'}
                </Typography>
                {!showForgotPassword ? (
                  <form onSubmit={isSignUp ? registerFormik.handleSubmit : loginFormik.handleSubmit} style={{ width: '100%', mt: 3 }}>
                    {isSignUp && (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={registerFormik.values.name}
                        onChange={registerFormik.handleChange}
                        error={registerFormik.touched.name && Boolean(registerFormik.errors.name)}
                        helperText={registerFormik.touched.name && registerFormik.errors.name}
                      />
                    )}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus={!isSignUp}
                      value={isSignUp ? registerFormik.values.email : loginFormik.values.email}
                      onChange={isSignUp ? registerFormik.handleChange : loginFormik.handleChange}
                      error={isSignUp ? registerFormik.touched.email && Boolean(registerFormik.errors.email) : loginFormik.touched.email && Boolean(loginFormik.errors.email)}
                      helperText={isSignUp ? registerFormik.touched.email && registerFormik.errors.email : loginFormik.touched.email && loginFormik.errors.email}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={isSignUp ? registerFormik.values.password : loginFormik.values.password}
                      onChange={isSignUp ? registerFormik.handleChange : loginFormik.handleChange}
                      error={isSignUp ? registerFormik.touched.password && Boolean(registerFormik.errors.password) : loginFormik.touched.password && Boolean(loginFormik.errors.password)}
                      helperText={isSignUp ? registerFormik.touched.password && registerFormik.errors.password : loginFormik.touched.password && loginFormik.errors.password}
                    />
                    {showAlert && (
                      <Typography variant="body2" color="error" align="center">
                        {alertMessage}
                      </Typography>
                    )}
                    <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                      {isSignUp ? 'Sign Up' : 'Sign In'}
                    </Button>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Link href="#" variant="body2" onClick={handleSwitch}>
                        {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
                      </Link>
                      {!isSignUp && (
                        <Link href="#" variant="body2" onClick={() => setShowForgotPassword(true)}>
                          Forgot password?
                        </Link>
                      )}
                    </Box>
                  </form>
                ) : (
                  <Box sx={{ width: '100%', mt: 3 }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="forgot-password-email"
                      label="Email Address"
                      name="forgot-password-email"
                      autoComplete="email"
                      autoFocus
                      value={forgotPasswordEmail}
                      onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2, mb: 2 }}
                      onClick={handleForgotPassword}
                    >
                      Send Reset Email
                    </Button>
                    {forgotPasswordMessage && (
                      <Typography variant="body2" color="textSecondary" align="center">
                        {forgotPasswordMessage}
                      </Typography>
                    )}
                    <Button
                      fullWidth
                      variant="text"
                      color="secondary"
                      onClick={() => setShowForgotPassword(false)}
                    >
                      Back to Sign In
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className="image-flipper-container">
              <Box className="flipper-wrapper">
                <ImageFlipper
                  frontImage={card1_front}
                  backImage={card1_back}
                  interval={5000} // Interval in milliseconds
                />
                <ImageFlipper
                  frontImage={card2_front}
                  backImage={card2_back}
                  interval={5000} // Interval in milliseconds
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default UserLogin;
