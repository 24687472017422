import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress, Tabs, Tab } from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2';
import axios from '../axiosConfig';
import './AdminStatistics.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const AdminStatistics = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalProducts: 0,
    productsSold: {
      daily: 0,
      weekly: 0,
      monthly: 0,
      yearly: 0,
    },
    ordersPlaced: {
      daily: 0,
      weekly: 0,
      monthly: 0,
      yearly: 0,
    },
    salesAmount: {
      daily: 0,
      weekly: 0,
      monthly: 0,
      yearly: 0,
    },
    topProducts: [],
  });
  const [tab, setTab] = useState('daily');

  useEffect(() => {
    const fetchStatistics = async () => {
      const adminToken = localStorage.getItem('adminToken');
      const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';
      try {
        const response = await axios.get('/admin/statistics', { headers });
        setStats(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching statistics', error);
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh"><CircularProgress /></Box>;
  }

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <Box className="admin-stats-container">
      <Typography variant="h4" gutterBottom className="page-title">Admin Statistics</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="stat-card">
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h4">{stats.totalUsers}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="stat-card">
            <Typography variant="h6">Total Products</Typography>
            <Typography variant="h4">{stats.totalProducts}</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Tabs value={tab} onChange={handleTabChange} className="tabs">
        <Tab label="Daily" value="daily" />
        <Tab label="Weekly" value="weekly" />
        <Tab label="Monthly" value="monthly" />
        <Tab label="Yearly" value="yearly" />
      </Tabs>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="stat-card">
            <Typography variant="h6">Products Sold</Typography>
            <Typography variant="h4">{stats.productsSold[tab]}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="stat-card">
            <Typography variant="h6">Orders Placed</Typography>
            <Typography variant="h4">{stats.ordersPlaced[tab]}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} className="stat-card">
            <Typography variant="h6">Sales Amount</Typography>
            <Typography variant="h4">{stats.salesAmount[tab]}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} className="chart-card">
            <Typography variant="h6" gutterBottom>Products Sold</Typography>
            <Bar
              data={{
                labels: ['Today', 'This Week', 'This Month', 'This Year'],
                datasets: [
                  {
                    label: 'Products Sold',
                    data: [
                      stats.productsSold.daily,
                      stats.productsSold.weekly,
                      stats.productsSold.monthly,
                      stats.productsSold.yearly,
                    ],
                    backgroundColor: 'rgba(75,192,192,0.6)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderWidth: 1,
                  },
                ],
              }}
              options={barChartOptions}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} className="chart-card">
            <Typography variant="h6" gutterBottom>Top 3 Most Sold Products</Typography>
            <Pie
              data={{
                labels: stats.topProducts.map(product => product.name),
                datasets: [
                  {
                    data: stats.topProducts.map(product => product.quantity),
                    backgroundColor: [
                      'rgba(255,99,132,0.6)',
                      'rgba(54,162,235,0.6)',
                      'rgba(255,206,86,0.6)',
                    ],
                  },
                ],
              }}
              options={{ responsive: true }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminStatistics;
