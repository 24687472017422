import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { Box, Typography, Button, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './OrderHistory.css';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const response = await axios.get('/orders/user', { headers });
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleReorder = async (order) => {
    try {
      const validProducts = await Promise.all(order.products.map(async (orderProduct) => {
        console.log(orderProduct);
        const availabilityResponse = await axios.post('/products/check-availability', {
          id: orderProduct.product._id,
          quantity: orderProduct.count
        });

        const { isOutOfStock, availableQuantity } = availabilityResponse.data;

        if (!isOutOfStock && availableQuantity >= orderProduct.count) {
          const productResponse = await axios.get(`/products/${orderProduct.product._id}`);
          const product = productResponse.data;
          console.log("product response:",product);
          return {
            id: product.product._id,
            name: product.product.name,
            price: product.product.price,
            amount: orderProduct.count,
            discount: 0, // Assuming no discount in reorder, update if needed
            image: product.product.images[0].url,
          };
        } else {
          return null;
        }
      }));

      const filteredProducts = validProducts.filter(product => product !== null);
      const cartItems = filteredProducts.map(product => ({
        name: product.name,
        amount: product.amount,
        id: product.id,
        discount: product.discount,
        price: product.price,
        image: product.image,
      }));
      navigate('/checkout', { state: { cartItems } });
    } catch (error) {
      console.error("Error handling reorder:", error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="order-history-container">
      <Typography variant="h4" gutterBottom>Order History</Typography>
      {orders.map((order) => (
        <Paper key={order._id} className="order-card">
          <Box className="order-header">
            <Typography variant="h6">Order ID: {order._id}</Typography>
            <Typography variant="body2">{new Date(order.createdAt).toLocaleDateString()}</Typography>
            <Typography variant="body2" className={`order-status ${order.status.toLowerCase()}`}>{order.status}</Typography>
          </Box>
          <Box className="order-details">
            {order.products.map(product => (
              <Box key={product.product} className="product-details">
                <img src={`${product.image.split('/upload/')[0]}/upload/f_auto/${product.image.split('/upload/')[1]}`} alt={product.name} />
                <Box className="product-info">
                  <Typography variant="body1">{product.name}</Typography>
                  <Typography variant="body2">Quantity: {product.count}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box className="order-footer">
            <Typography variant="h6">Total Amount: Rs {order.amount}</Typography>
            <Button variant="contained" color="primary" onClick={() => handleReorder(order)}>Reorder</Button>
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default OrderHistory;
