import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Pagination, Box, CircularProgress } from '@mui/material';
import axios from '../axiosConfig'; // Import the configured Axios instance
import ProductCard from '../components/ProductCard.js';
import { deslugify } from '../utils/slugify';

import './Categories.css';

const Categories = () => {
  const { type, identifier } = useParams(); // Destructure type and identifier from params
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const productsPerPage = 12;

  const fetchProducts = async (type, identifier, page) => {
    setLoading(true);
    try {
      const response = await axios.get(`/products/${type}/${identifier}?page=${page}&limit=${productsPerPage}`);
      setProducts(response.data.products);
      setTotalProducts(response.data.totalProducts);
    } catch (error) {
      console.error('Error fetching the products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(type, identifier, currentPage);
  }, [type, identifier, currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="shopcategory">
      <Typography align="center" sx={{ fontSize: '30px', marginBottom: '20px', marginTop: "30px" }}>{deslugify(identifier).replace(/=S=/g, "'s")}</Typography>
      <div className="shopcategory-indexSort">
        <p><span>Showing {(currentPage - 1) * productsPerPage + 1} - {Math.min(currentPage * productsPerPage, totalProducts)}</span> out of {totalProducts} Products</p>
      </div>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <div className="shopcategory-products">
          <Grid container spacing={3}>
            {products.map((item, i) => (
              <Grid item xs={12} sm={3} key={i}>
                <ProductCard key={item._id} product={item} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <Box display="flex" justifyContent="center" mt={4}>
        <Pagination
          count={Math.ceil(totalProducts / productsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default Categories;
