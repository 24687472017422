import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Modal,
  TextField,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider
} from '@mui/material';
import { AddCircleOutline, Edit, Delete, CheckCircle, Cancel } from '@mui/icons-material';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import './SalesOffersPage.css';

const SalesOffersPage = () => {
  const [offers, setOffers] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  const [shippingFee, setShippingFee] = useState('');
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [isPromoCodeModalOpen, setIsPromoCodeModalOpen] = useState(false);
  const [offerName, setOfferName] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();
  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  useEffect(() => {
    fetchOffers();
    fetchPromoCodes();
    fetchShippingFee(); // Fetch initial shipping fee
  }, []);

  const fetchOffers = async () => {
    try {
      const response = await axios.get('/sales');
      setOffers(response.data);
    } catch (error) {
      console.error('Error fetching offers:', error);
    }
  };

  const fetchPromoCodes = async () => {
    try {
      const response = await axios.get('/promocode', { headers });
      setPromoCodes(response.data);
    } catch (error) {
      console.error('Error fetching promo codes:', error);
    }
  };

  const fetchShippingFee = async () => {
    try {
      const response = await axios.post('/orders/shipping');
      setShippingFee(response.data.shippingFee);
    } catch (error) {
      console.error('Error fetching shipping fee:', error);
      setSnackbarMessage('Error fetching shipping fee');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  

  const handleUpdateShippingFee = async () => {
    try {
      await axios.put('/orders/shipping-fee', { shippingFee }, { headers });
      setSnackbarMessage('Shipping fee updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating shipping fee:', error);
      setSnackbarMessage('Error updating shipping fee');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCreateOffer = async () => {
    setIsOfferModalOpen(false);
    try {
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
  
      const offerData = {
        name: offerName,
        startDate: today
      };
  
      const response = await axios.post('/sales', offerData, { headers });
      setSnackbarMessage('Offer created successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchOffers();
      navigate(`/admin/sales/${response.data._id}`);
    } catch (error) {
      setSnackbarMessage('Error creating offer');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  
  const handleEditOffer = (offerId) => {
    navigate(`/admin/sales/${offerId}`);
  };

  const handleDeleteOffer = async (offerId) => {
    try {
      await axios.delete(`/sales/${offerId}`, { headers });
      setSnackbarMessage('Offer deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchOffers();
    } catch (error) {
      setSnackbarMessage('Error deleting offer');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleGeneratePromoCode = () => {
    const code = Math.random().toString(36).substring(2, 10).toUpperCase();
    setPromoCode(code);
  };

  const handleCreatePromoCode = async () => {
    setIsPromoCodeModalOpen(false);
    try {
      const response = await axios.post('/promocode', { code: promoCode, discountPercentage }, { headers });
      setSnackbarMessage('Promo code created successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchPromoCodes();
    } catch (error) {
      console.log(error);
      setSnackbarMessage('Error creating promo code');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeletePromoCode = async (promoCodeId) => {
    try {
      await axios.delete(`/promocode/${promoCodeId}`, { headers });
      setSnackbarMessage('Promo code deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchPromoCodes();
    } catch (error) {
      setSnackbarMessage('Error deleting promo code');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Sales Offers
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Button variant="contained" color="primary" onClick={() => setIsOfferModalOpen(true)}>
          Create New Offer
        </Button>
        <Button variant="contained" color="primary" onClick={() => setIsPromoCodeModalOpen(true)}>
          Generate New Promo Code
        </Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Offers
          </Typography>
          <Grid container spacing={4}>
            {offers.map((offer) => (
              <Grid item xs={12} sm={6} key={offer._id}>
                <Card>
                  <CardMedia
                    component="img"
                    height="140"
                    image={offer.bannerImage || 'https://via.placeholder.com/140'}
                    alt={offer.name}
                  />
                  <CardContent>
                    <Typography variant="h5">{offer.name}</Typography>
                    <Typography variant="body2">Start Date: {new Date(offer.startDate).toLocaleDateString()}</Typography>
                    <Typography variant="body2">End Date: {new Date(offer.endDate).toLocaleDateString()}</Typography>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                      <IconButton color="primary" onClick={() => handleEditOffer(offer._id)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDeleteOffer(offer._id)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} md={5}>
          <Typography variant="h5" gutterBottom>
            Promo Codes
          </Typography>
          
          

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Promo Code</TableCell>
                  <TableCell>Discount %</TableCell>
                  <TableCell>Redeem Status</TableCell>
                  <TableCell>Redeem Date</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promoCodes.map((code) => (
                  <TableRow key={code._id}>
                    <TableCell>{code.code}</TableCell>
                    <TableCell>{code.discountPercentage}</TableCell>
                    <TableCell>
                      {code.redeemStatus ? (
                        <CheckCircle color="success" />
                      ) : (
                        <Cancel color="error" />
                      )}
                    </TableCell>
                    <TableCell>{code.redeemDate ? new Date(code.redeemDate).toLocaleDateString() : 'N/A'}</TableCell>
                    <TableCell>{new Date(code.creationDate).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <IconButton color="secondary" onClick={() => handleDeletePromoCode(code._id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Shipping Fee Section */}
          <Box display="flex" alignItems="center" mb={2} style={{marginTop: "30px"}}>
            <TextField
              label="Shipping Fee"
              variant="outlined"
              type="number"
              value={shippingFee}
              onChange={(e) => setShippingFee(e.target.value)}
              fullWidth
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleUpdateShippingFee}
              sx={{ ml: 2 }}
            >
              Change
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Modal open={isOfferModalOpen} onClose={() => setIsOfferModalOpen(false)}>
        <Box className="modal-box">
          <Typography variant="h6" gutterBottom>
            Create New Offer
          </Typography>
          <TextField
            label="Offer Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={offerName}
            onChange={(e) => setOfferName(e.target.value)}
          />
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button variant="contained" color="primary" onClick={handleCreateOffer}>
              Create Offer
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setIsOfferModalOpen(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isPromoCodeModalOpen} onClose={() => setIsPromoCodeModalOpen(false)}>
        <Box className="modal-box">
          <Typography variant="h6" gutterBottom>
            Generate New Promo Code
          </Typography>
          <TextField
            label="Promo Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={promoCode}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button variant="contained" color="primary" onClick={handleGeneratePromoCode} sx={{ mb: 2 }}>
            Generate Code
          </Button>
          <TextField
            label="Discount Percentage"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
          />
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button variant="contained" color="primary" onClick={handleCreatePromoCode}>
              Save Promo Code
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setIsPromoCodeModalOpen(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SalesOffersPage;
