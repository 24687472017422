import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';

const BreadcrumbsComponent = ({ category, productName }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="inherit" href="/">Home</Link>
      <Link underline="hover" color="inherit" href={`/category/${category}`}>{category}</Link>
      <Typography color="textPrimary">{productName}</Typography>
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
