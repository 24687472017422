import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, Pagination, Card, CardContent, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import './ListOrders.css';

const ListOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [shopAddressFilter, setShopAddressFilter] = useState('All');
  const [timeFilter, setTimeFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const ordersPerPage = 9;
  const navigate = useNavigate();
  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  useEffect(() => {
    fetchOrders();
  }, [currentPage, statusFilter, shopAddressFilter, timeFilter]);

  const fetchOrders = () => {
    axios.get('/orders', {
      headers,
      params: {
        page: currentPage,
        limit: ordersPerPage,
        status: statusFilter,
        address: shopAddressFilter,
        time: timeFilter,
      },
    })
      .then(response => {
        console.log(response.data);
        setOrders(response.data.orders);
        setTotalOrders(response.data.totalOrders);
        setFilteredOrders(response.data.orders); // assuming filterOrders runs on data
      })
      .catch(error => console.error('Error fetching orders:', error));
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleShopAddressFilterChange = (event) => {
    setShopAddressFilter(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleTimeFilterChange = (event) => {
    setTimeFilter(event.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleOrderClick = (orderId) => {
    navigate(`/admin/orders/${orderId}`);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box className="list-orders">
      <Typography variant="h4" gutterBottom>Shop Orders</Typography>
      <Box className="filters">
        <Select value={statusFilter} onChange={handleStatusFilterChange} displayEmpty>
          <MenuItem value=''>All</MenuItem>
          <MenuItem value='Not processed'>Not processed</MenuItem>
          <MenuItem value='Processing'>Processing</MenuItem>
          <MenuItem value='Shipped'>Shipped</MenuItem>
          <MenuItem value='Delivered'>Delivered</MenuItem>
          <MenuItem value='Cancelled'>Cancelled</MenuItem>
        </Select>
        <Select value={shopAddressFilter} onChange={handleShopAddressFilterChange} displayEmpty>
          <MenuItem value='All'>All Orders</MenuItem>
          <MenuItem value='HerbiMed, Big City Plaza Liberty Chowk Gulberg-3 Lahore'>Gulberg</MenuItem>
          <MenuItem value='HerbiMed, H Block Phase-1 DHA Lahore'>DHA</MenuItem>
        </Select>
        <Select value={timeFilter} onChange={handleTimeFilterChange} displayEmpty>
          <MenuItem value='All'>All Time</MenuItem>
          <MenuItem value='Today'>Today</MenuItem>
          <MenuItem value='In Last Week'>In Last Week</MenuItem>
          <MenuItem value='In Last Month'>In Last Month</MenuItem>
          <MenuItem value='In Last Year'>In Last Year</MenuItem>
        </Select>
      </Box>
      <Box className="orders-container">
        {filteredOrders.map(order => (
          <Card key={order._id} className="order-card" onClick={() => handleOrderClick(order._id)}>
            <CardActionArea>
              <CardContent>
                <Typography variant="h6"><strong>Order ID:</strong> {order._id}</Typography>
                <Typography><strong>Amount:</strong> Rs {order.amount}</Typography>
                <Typography><strong>Status:</strong> {order.status}</Typography>
                <Typography><strong>Date:</strong> {new Date(order.createdAt).toLocaleString()}</Typography>
                <Typography><strong>Address:</strong> {order.address}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
      <Pagination
        count={Math.ceil(totalOrders / ordersPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        className="pagination"
      />
    </Box>
  );
};

export default ListOrders;
