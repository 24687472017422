import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const token = localStorage.getItem('adminToken');
  const loginDate = localStorage.getItem('admin_login_date');
  
  if (!token || !loginDate) {
    return <Navigate to="/admin/login" />;
  }

  const loginTime = new Date(loginDate).getTime();
  const currentTime = new Date().getTime();
  const hoursDiff = (currentTime - loginTime) / (1000 * 60 * 60);

  if (hoursDiff > 20) {
    localStorage.removeItem('adminToken');
    localStorage.removeItem('admin_login_date');
    return <Navigate to="/admin/login" />;
  }

  return children;
};

export default ProtectedRoutes;
