import React, { useState } from 'react';
import { AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText, Box, IconButton, Drawer } from '@mui/material';
import { History, ExitToApp, Person, Menu } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { NavLink, useNavigate } from 'react-router-dom';
import './UserSidebar.css';
import logo from '../images/Logo.png';

const UserSidebar = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('cart');
    localStorage.removeItem('login_date');
    navigate('/');
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navbarContent = (
    <List className="navbar-list">
      <NavLink to="/userhome/orderhistory" className="nav-link" activeClassName="active-link">
        <ListItem button>
          <ListItemIcon><History /></ListItemIcon>
          <ListItemText primary="Order History" />
        </ListItem>
      </NavLink>
      <NavLink to="/userhome/profile" className="nav-link" activeClassName="active-link">
        <ListItem button>
          <ListItemIcon><Person /></ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
      </NavLink>
      <NavLink to="/userhome/changepassword" className="nav-link" activeClassName="active-link">
        <ListItem button>
          <ListItemIcon><LockOutlinedIcon /></ListItemIcon>
          <ListItemText primary="Change Password" />
        </ListItem>
      </NavLink>
      <ListItem button onClick={handleLogout} className="nav-link">
        <ListItemIcon><ExitToApp /></ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar position="sticky" className="navbar">
        <Toolbar className="toolbar">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            className="menu-button"
          >
            <Menu />
          </IconButton>
          <Box className="logo-container">
            <NavLink to="/" className="logo-link">
              <img src={logo} alt="Logo" className="navbar-logo" />
            </NavLink>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {navbarContent}
        </Box>
      </Drawer>
    </>
  );
};

export default UserSidebar;
