import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { createSlug } from '../utils/slugify';
import './AllProducts.css';

const banner1 = 'https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721742740/popular_fzlgaq.jpg';

const subcategories = [
    { name: 'All', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886116/allproducts_tui5pb.jpg" },
    { name: 'Bone Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639441/bone_lbkcss.jpg" },
    { name: 'Brain Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639442/brain_p36up0.jpg" },
    { name: "Children's Health", image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639410/children_an3h16.jpg" },
    { name: 'Diabetic Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639410/diabetic_g9ge3b.jpg" },
    { name: 'Digestive Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639411/digestive_h9pauw.jpg" },
    { name: 'Hair, Skin & Nail Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639411/hair_jt6w7e.jpg" },
    { name: 'Heart & Circulatory Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639411/heart_gccgew.jpg" },
    { name: 'Immune Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639415/immune_hwa4kt.jpg" },
    { name: 'Liver & Detox Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639418/liver_qzpwpg.jpg" },
    { name: "Men's Health", image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639419/men_cklgbl.jpg" },
    { name: 'Sleep & Relaxation', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639430/sleep_xatk1l.jpg" },
    { name: 'Vision Health', image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639431/vision_aagaak.jpg" },
    { name: "Women's Health", image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721639432/women_oasink.jpg" },
];


const AllProducts = () => {
  return (
    <Box className="products-page">
      <Box className="banner" style={{ backgroundImage: `url(${banner1})` }}></Box>
      <Typography variant="h4" gutterBottom className="page-title">
        What would you like help with?
      </Typography>
      <Grid container spacing={3} className="tile-container">
        {subcategories.map((subcategory, index) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
            <Link to={subcategory.name === "All" ? "/category/All" : `/subcategory/${createSlug(subcategory.name)}`}>
              <Box
                className="tile"
                style={{ backgroundImage: `url(${subcategory.image})` }}
              >
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AllProducts;
