import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Box } from '@mui/material';

const partnersData = [
  {
    name: "Nature's Truth",
    description: `Nature’s Truth creates trusted, high-quality vitamins and supplements that fit seamlessly into your healthy lifestyle. The story of Nature’s Truth begins with its founders, a family of 2nd and 3rd generation vitamin innovators who utilized their decades of knowledge and expertise to create a brand that people could truly feel good about using.`,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhued2V-WGT6zkg1Fe_BRJ1KWPvJsBvPpYDg&s'
  },
  {
    name: "Piping Rock",
    description: `Piping Rock exceeds conventional quality standards by adhering to a meticulous quality protocol that covers every aspect, including material sourcing, formulation, testing, production, and fulfillment. This dedication is in perfect harmony with the stringent Good Manufacturing Practices (GMP) standards established by the U.S. Food and Drug Administration (FDA).`,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjK5BLzLbMIhIC3vDRUd4zaATCAWGk7bi_Vg&s'
  },
  {
    name: "Swanson",
    description: `Swanson provides hundreds of honest-to-goodness health products because we carefully select and source our ingredients and only work with partners we know and trust. To stand by our word, we also offer a 100% money-back guarantee—so you can shop with peace of mind and develop a routine that leads to results.`,
    image: 'https://upload.wikimedia.org/wikipedia/commons/3/32/Swanson_Vitamins_Primary_Logo.jpg'
  },
  {
    name: "Puritan's Pride",
    description: `Puritan’s Pride have grown and thrived for more than 50 years because one thing has never changed: Our mission to make the highest quality nutritional supplements available at the best value.`,
    image: 'https://www.nestlehealthscience.com/sites/default/files/BRAND_LOGO_PURITANSPRIDE.jpeg'
  },
  {
    name: "Bestvite",
    description: `The principals behind Bestvite rely upon years of experience in our quest to bring you the industry’s highest quality nutritional supplements and functional foods at affordable prices. We seek not only the finest products across the globe.`,
    image: 'https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/ioorm7gas1jpxpbcojc8'
  }
];

const Partners = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Typography variant="h3" gutterBottom align="center" color="primary">
        Our Partners
      </Typography>
      <Typography variant="h6" gutterBottom align="center" sx={{ mb: 5 }}>
        At Herbi Med, we are committed to your health and well-being, that’s why we partner with the best! We are proud to be the sole distributors of Piping Rock and Nature’s Truth in Pakistan, bringing you a wide range of quality products that support your health goals.
      </Typography>
      <Grid container spacing={4}>
        {partnersData.map((partner, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                alt={partner.name}
                height="200"
                image={partner.image}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  {partner.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {partner.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Partners;
