import React, { useState, useEffect } from 'react';
import './ImageFlipper.css';

const ImageFlipper = ({ frontImage, backImage, interval = 3000 }) => {
  const [isFront, setIsFront] = useState(true);

  useEffect(() => {
    const flipInterval = setInterval(() => {
      setIsFront(prev => !prev);
    }, interval);

    return () => clearInterval(flipInterval);
  }, [interval]);

  const addAutoFormat = (imageUrl) => {
    const urlParts = imageUrl.split('/upload/');
    return `${urlParts[0]}/upload/f_auto/${urlParts[1]}`;
  };

  const frontImageUrl = addAutoFormat(frontImage);
  const backImageUrl = addAutoFormat(backImage);

  return (
    <div className="image-flipper">
      <div className={`flipper ${isFront ? 'flip' : ''}`}>
        <img src={frontImageUrl} alt="Front" className="front" />
        <img src={backImageUrl} alt="Back" className="back" />
      </div>
    </div>
  );
};

export default ImageFlipper;

