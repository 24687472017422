import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './UserHome.css';
import UserSidebar from '../components/UserSidebar';
import OrderHistory from './OrderHistory';
import ProfilePage from './ProfilePage';
import ChangePassword from './ChangePassword';

const UserHome = () => {
  return (
    <div className="user-home">
      
        <Routes>
          <Route index element={<><UserSidebar /><OrderHistory /></>} />
          <Route path="orderhistory" element={<><UserSidebar /><OrderHistory /></>} />
          <Route path="profile" element={<><UserSidebar /><ProfilePage /></>} />
          <Route path="changepassword" element={<><UserSidebar /><ChangePassword /></>} />
        </Routes>
    </div>
  );
};

export default UserHome;
