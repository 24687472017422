import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  IconButton,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Snackbar,
  Alert,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import axios from '../axiosConfig';
import './EditFeatured.css';
import { useNavigate, useParams } from 'react-router-dom';
import PriceDisplay from '../components/PriceDisplay';

const EditFeatured = () => {
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [tagline, setTagline] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { id } = useParams();
  const navigate = useNavigate();

  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  useEffect(() => {
    const fetchFeaturedTab = async () => {
      try {
        const response = await axios.get(`/featured/${id}`);
        const { tagline, products } = response.data;
        setTagline(tagline);
        setFeaturedProducts(products || []);
      } catch (error) {
        console.error('Error fetching featured tab:', error);
      }
    };

    fetchFeaturedTab();
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/products/search');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, products]);

  const handleAddProduct = (product) => {
    const existingProduct = featuredProducts.find((item) => item._id === product._id);
    if (!existingProduct) {
      setFeaturedProducts([
        ...featuredProducts,
        {
          _id: product._id,
          name: product.name,
          price: product.price,
          packSize: product.packSize,
          image: product.images[0].url,
        },
      ]);
    }
  };

  const handleRemoveProduct = (_id) => {
    setFeaturedProducts(featuredProducts.filter((item) => item._id !== _id));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (product) => {
    if (featuredProducts.some((item) => item._id === product._id)) {
      handleRemoveProduct(product._id);
    } else {
      handleAddProduct(product);
    }
  };

  const handleSave = async () => {
    const payload = { tagline, products: featuredProducts };

    try {
      const response = await axios.put(`/featured/${id}`, payload, { headers });
      setSnackbarMessage('Featured tab updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error updating featured tab!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error updating featured tab:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg" className="container">
      <Typography variant="h4" gutterBottom className="heading">Edit Featured Tab</Typography>
      <TextField
        label="Tagline"
        variant="outlined"
        fullWidth
        value={tagline}
        onChange={(e) => setTagline(e.target.value)}
        margin="normal"
        className="text-field"
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Products</Typography>
          <TextField
            label="Search Products"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            margin="normal"
            className="text-field"
          />
          <List className="product-list">
            {searchResults.map((product) => (
              <ListItem key={product._id} className="card">
                <ListItemAvatar>
                  <Avatar 
                    src={product.images && product.images[0] 
                      ? `${product.images[0].url.split('/upload/')[0]}/upload/f_auto/${product.images[0].url.split('/upload/')[1]}` 
                      : ''
                    } 
                    alt={product.name} 
                    className="card-avatar" 
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={`${product.name}`}
                  secondary={`Price: Rs ${product.price}`}
                  className="card-text"
                />
                <Checkbox
                  checked={featuredProducts.some((item) => item._id === product._id)}
                  onChange={() => handleCheckboxChange(product)}
                  color="primary"
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Featured Products</Typography>
          {featuredProducts.map((item) => (
            <Card key={item._id} className="card">
              <CardContent className="card-content">
                <Avatar 
                  src={item.image 
                    ? `${item.image.split('/upload/')[0]}/upload/f_auto/${item.image.split('/upload/')[1]}` 
                    : ''
                  } 
                  alt={item.name} 
                  className="card-avatar" 
                />

                <Box className="card-text">
                  <Typography variant="body1">{item.name}</Typography>
                  <PriceDisplay productId={item._id} typographyVariant= "body2" price={item.price}/>
                </Box>
                <IconButton color="secondary" onClick={() => handleRemoveProduct(item._id)} className="card-actions">
                  <RemoveCircleOutline />
                </IconButton>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
      <Box className="button-group">
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => navigate('/admin/featured')}>
          Go Back
        </Button>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} className="snackbar">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditFeatured;
