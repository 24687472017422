import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'; // Import the Edit icon
import axios from '../axiosConfig';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import './Featured.css';

const Featured = () => {
  const [open, setOpen] = useState(false);
  const [tagline, setTagline] = useState('');
  const [featuredTabs, setFeaturedTabs] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const adminToken = localStorage.getItem('adminToken');
  const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';

  useEffect(() => {
    fetchFeaturedTabs();
  }, []);

  const fetchFeaturedTabs = async () => {
    try {
      const response = await axios.get('/featured', { headers });
      setFeaturedTabs(response.data);
    } catch (error) {
      console.error('Error fetching featured tabs:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleCreateFeaturedTab = async () => {
    try {
      const response = await axios.post('/featured', { tagline }, { headers });
      setFeaturedTabs([...featuredTabs, response.data]);
      setSnackbarMessage('Featured tab created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTagline('');
      handleClose();
    } catch (error) {
      setSnackbarMessage('Error creating featured tab!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error creating featured tab:', error);
    }
  };

  const handleDeleteFeaturedTab = async (id) => {
    try {
      await axios.delete(`/featured/${id}`, { headers });
      setFeaturedTabs(featuredTabs.filter(tab => tab._id !== id));
      setSnackbarMessage('Featured tab deleted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error deleting featured tab!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      console.error('Error deleting featured tab:', error);
    }
  };

  const handleEditFeaturedTab = (tab) => {
    navigate(`/admin/editfeatured/${tab._id}`);
  };

  return (
    <Box className="featured-container">
      <Typography variant="h4" align="center" gutterBottom>Featured Tabs</Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpen} className="create-button">
        Create Featured Tab
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal-content">
          <Typography variant="h6" gutterBottom>Create New Featured Tab</Typography>
          <TextField
            label="Tagline"
            variant="outlined"
            fullWidth
            value={tagline}
            onChange={(e) => setTagline(e.target.value)}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleCreateFeaturedTab} className="submit-button">
            Create
          </Button>
        </Box>
      </Modal>

      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tagline</TableCell>
              <TableCell>Products Count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {featuredTabs.map((tab) => (
              <TableRow key={tab._id}>
                <TableCell>{tab.tagline}</TableCell>
                <TableCell>{tab.products ? tab.products.length : 0}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditFeaturedTab(tab)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFeaturedTab(tab._id)} color="secondary">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Featured;
