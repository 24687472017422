import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Pagination, Tooltip, Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import axios from '../axiosConfig';
import './AuditLogs.css';

const AuditLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 15;

  useEffect(() => {
    const fetchAuditLogs = async () => {
      const adminToken = localStorage.getItem('adminToken');
      const headers = adminToken ? { Authorization: `Bearer ${adminToken}` } : '';
      try {
        const response = await axios.get('/admin/audit-logs', { headers });
        setLogs(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching audit logs:', error);
        setLoading(false);
      }
    };

    fetchAuditLogs();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate the logs to display on the current page
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

  if (loading) {
    return <CircularProgress />;
  }

  const headers = [
    { label: 'Admin Name', key: 'adminName' },
    { label: 'Action', key: 'action' },
    { label: 'Details', key: 'details' },
    { label: 'Timestamp', key: 'timestamp' },
  ];

  const csvReport = {
    data: logs,
    headers: headers,
    filename: 'Audit_Logs_Report.csv'
  };

  return (
    <Container className="audit-logs-container">
      <div className="header-container">
        <Typography variant="h4" gutterBottom>
          Audit Logs
        </Typography>
        <Button variant="contained" color="primary">
          <CSVLink {...csvReport} style={{ textDecoration: 'none', color: 'white' }}>
            Export to CSV
          </CSVLink>
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Admin Name</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLogs.map((log) => (
              <TableRow key={log._id}>
                <TableCell>{log.adminName}</TableCell>
                <TableCell>{log.action}</TableCell>
                <TableCell className="truncate">
                  <Tooltip title={log.details}>
                    <span>{log.details}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(logs.length / logsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        className="pagination"
      />
    </Container>
  );
};

export default AuditLogs;
